import React, { useState, useEffect } from 'react';
import './userDashNav.css';
import { getUsername, logout } from '../../api/authRequests';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';


const UserDashNav = ({ setLoggedin,setlogoutmsg,setAdminLoggedin }) => {
  const [username, setUsername] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (username === '') {
      getUsername(setUsername, setLoggedin);
    }
  }, [username, setLoggedin]);
  
  const handleLogout = () => {
    setlogoutmsg("Logged out")
    setLoggedin(false);
    setAdminLoggedin(false);
    logout();
    document.cookie="";
    navigate('/');
  };

  return (
    <div className='userNav'>
      <img className="usernavlogo" src="../logoblack.png" alt="logo" />
      <div className='udashleft'>
        <img className="userprofile" src="../profileimg.png" alt="profile" />
        <div className='userprofname'>{username === '' ? 'Loading..' : username}</div>
        <div className='logout' onClick={handleLogout}>
          <FontAwesomeIcon icon={faSignOutAlt} aria-hidden="true" className='fontawsome'/> <span className='log'>Logout</span>
        </div>
      </div>
    </div>
  );
};

export default UserDashNav;
