import axios from 'axios'
import emailjs from '@emailjs/browser';

axios.defaults.withCredentials = true;


export const loginreq=(email,password,setLoggedin,setLoginmsg,setShowSpinner,setAdminLoggedin)=>
{
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/auth/login`,{email,password}).then((data)=>
    {
       
        if(data.data.response === "Login sucessfull")
        {
            if(data.data.role === "User")
            {
                document.cookie = "token" + "=" + data.data.token;
                setLoggedin(true);
                setShowSpinner(false);
            }
            else if(data.data.role === "Admin"){
                document.cookie = "admintoken" + "=" + data.data.token;
                setAdminLoggedin(true);
                setShowSpinner(false);
            }
        }
        else{
            setLoginmsg(data.data.response);
            setShowSpinner(false);
        }
    })
    
}

export const signup=(username,email,password,setRedirectlogin,setLoginmsg,setSignupmsg,setShowSpinner)=>
{
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/auth/register`,{username,email,password}).then((data)=>
    {
      
        if(data.data.response === "User Created")
        {
            setRedirectlogin(true);
            setLoginmsg("User Created, Please Login.!");
            setShowSpinner(false);
        }
        else
        {
            setSignupmsg("User already found please login");
            setShowSpinner(false);
        }
        
    })
    
}

export const getUsername=async(setUsername,setLoggedin)=>
{
    await axios.get(`${process.env.REACT_APP_BACKEND_URL}/info/username`).then((data)=>
    {
        if(data.data.response === "Token not found")
        {
            setLoggedin(false);
        }
        else{
            setUsername((data.data.response).charAt(0).toUpperCase() + (data.data.response).slice(1));
        }
    })
}

export const getPoints = async(setPoints)=>
{
    await axios.get(`${process.env.REACT_APP_BACKEND_URL}/info/points`).then((data)=>
    {
        setPoints(data.data.response)
    })
}



    export const fetchUsers = async (setUsers) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api-admin/users`);
            const users = response.data.filter(user => user.role === 'User');
            setUsers(users);
        } catch (error) {
            throw error;
        } 
    };

    export const checktoken = async (setLoggedin) => {
        try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/auth/token`);
                console.log(response)
                if(response.data.response === "Token found")
                {
                    localStorage.setItem("token",response.data.token);
                }
                else
                {
                    setLoggedin(false);
                    localStorage.setItem("token",null);
                }
        } catch (error) {
            throw error;
        } 
    };

    export const UpdateRedeemPoints = async (email,points,setReload,setDashmsg,setRedeemPoints,fetchAllData) => {
        try {
                const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/info/updatepoints`,{email:email,points:points});
                if(response.data.response === "Points updated sucessfully")
                {
                    setDashmsg("Points updated sucessfully")
                    fetchAllData();
                }
                else
                {
                    setDashmsg(response.data.response);
                }
        } catch (error) {
            throw error;
        } 
    };
   

export const sendPassResetEmail = async(email,resetotp,setOtpsentstate,setLoginmsg, setShowSpinner)=>
    {

        await axios.post(`${process.env.REACT_APP_BACKEND_URL}/auth/checkemail`,{email:email}).then((data)=>
            {
               if(data.data === "Email Found")
                {
                    var template_params = {
                        'to_name': email,
                        'to_email': email,
                        'otp': resetotp,
                    };
            
                    emailjs.send(
                        process.env.REACT_APP_EMAILJS_SERVICE_ID,
                        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
                        template_params,
                        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
                    ).then(
                        function(response) {
                           if(response.status === 200)
                            {
                                setOtpsentstate(true) 
                            }
                            else
                            {
                                setShowSpinner(false)
                                setLoginmsg("Error Sending OTP");
                            }
                    }, function(error) {
                        console.error('Error sending email:', error);
                    });
                }
                else
                {
                    setShowSpinner(false)
                    setLoginmsg("Email-id not found. Please Signup.!");
                }
            })
        
    }

    export const resetPassword = async(useremail,password,setredirectlogin,setErrorMessagetoast,setLoginmsg)=>
        {
            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/auth/resetpass`,{useremail:useremail,password:password}).then((data)=>
            {
               if(data.data === "Password updated sucessfully")
                {
                    setLoginmsg("Password updated sucessfully")
                    setredirectlogin(true);
                }
                else
                {
                    setErrorMessagetoast(data.data)
                }
            })
        }

    export const logout=async()=>
        {
           const response=await axios.get(`${process.env.REACT_APP_BACKEND_URL}/logout`)
           console.log(response);
        }


        