import React from 'react'
import './userPoints.css'

const UserPoints = ({ points }) => {
  return (
    <div className='user_points_box_fixed'>
    <div className='user_points_box'>
      <div className='left_box'>
      Do not forget to claim your offers by redeeming the points in the in-store purchase
      </div>
      <div className='right_box'>
      <img className="star" src="../Vector.png" alt="" />
      <div>
      <div className='top_pts'>{(points === -1)? "wait.." : points }</div>
      <div className='by'></div>
      <div className='down_pts'>500</div>
      </div>
      </div>
    </div>
    </div>
  )
}

export default UserPoints
