import React from "react";
import "./radiant.css";

const Radiant = () => {
  return (
    <div className="radiant-container">
      <div className="radiant-title">Radiant threads</div>
      <div className="radiant-flex">
      <div className="heliocentric">
          <img src="heliocentric.jpg" alt="" className="heliocentric-image" />
        </div>
        <div className="radiant-paraflex">
          <div className="radiant-para">
            This concept, akin to the discovery of heliocentrism in astronomy,
            placed the individual at the center of their style universe. Each
            person became a luminary force, dictating their own orbit in the
            vast cosmos of clothing choices.
          </div>
          <div className="radiant-para">
            Gone were the days of conforming to fashion norms dictated by
            external forces. Instead, individuals were encouraged to shine
            brightly in their unique sense of style, casting their own
            gravitational pull on the fashion landscape.
          </div>
          <div className="radiant-para">
            As the fashion universe evolved, diversity, self-expression, and
            individuality took center stage. From mixing and matching different
            brands to crafting bespoke pieces, the fashion cosmos became a
            playground of endless possibilities.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Radiant;
