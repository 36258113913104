import React from "react";
import "./brandsYouLove.css";


const BrandsYouLove = () => {
  return (
    <div className="brandsYouLove-container">
      <div className="brandsYouLove-title">
        From the
        <br />
        brands you love
      </div>
      <div className="brandsYouLove-flex">
        <div className="brandsYouLove-flex-left">
          <img src="Adidasbw.jpg" alt="" className="adidasbw" />
          <div className="brandsYouLove-words">
            Reach the <span className="brandsYouLove-span">Brands</span>
          </div>
        </div>
        <div className="brandsYouLove-mainflex-right">
          <div className="brandsYouLove-flex-right">
            <img src="Adidasred.jpg" alt="" className="adidasred" />
            <div className="brandsYouLove-flexdown-right">
              <div className="brandsYouLove-two">
                Find & Elevate your fashion
              </div>
              <img src="Puma.jpg" alt="" className="puma" />
            </div>
          </div>
          <div className="vertical-wordings">
            <h3>Nike</h3>
            <h3>Arrow.</h3>
            <h3>Addidas.</h3>
            <h3>Puma.</h3>
            <h3>Lacoste.</h3>
            <h3>USPoloAssn.</h3>
            <h3>Puma.</h3>
          </div>
        </div>
      </div>
      <div className="mob_container">
      <img src="Adidasred.jpg" alt="" className="adidasredmob" />
      <div className="mob_downdiv">
      <img src="Adidasbw.jpg" alt="" className="adidasbwmob" />
      <img src="Puma.jpg" alt="" className="pumamob" />
      </div>
      </div>
      
    </div>
  );
};

export default BrandsYouLove;
