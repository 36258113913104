import React, { useEffect, useState } from 'react';
import './ownerDashboard.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { UpdateRedeemPoints, fetchUsers } from '../../api/authRequests';
import { ToastContainer, toast } from 'react-toastify';


const OwnerDashboard = () => {
  const [searchInput, setSearchInput] = useState("");
  const [users, setUsers] = useState([]);
  const [redeemPoints, setRedeemPoints] = useState(0);
  const [reload, setReload] = useState(false);
  const [dashmsg, setDashmsg] = useState("");
  
  useEffect(() => {
    if (dashmsg !== "") {
        toast(dashmsg);
        setDashmsg("");
    }
}, [dashmsg]);

  useEffect(() => {
    fetchAllData();
}, []);

// useEffect(()=>
// {
  
// },[reload,setReload])

const fetchAllData = async () => {
    try {
        const userData = await fetchUsers(setUsers);
    } catch (error) {
        console.log("Error fetching users:", error);
    } 
};


  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  const filterTableData = (data, searchInput) => {
        const searchTerms = searchInput.toLowerCase();
        return data.filter(row =>
          row.username.toLowerCase().startsWith(searchTerms) ||
          row.gmail.toLowerCase().startsWith(searchTerms)
        );    
  };

  const handleMinusClick = (index) => {
    const newData = [...users];
    if (newData[index].EP > 0) {
      newData[index].EP -= 1;
      setUsers(newData);
    }
  };

  const handlePlusClick = (index) => {
    const newData = [...users];
    newData[index].EP += 1;
    setUsers(newData);
  };

  const handleButtonMouseDown = (index, type) => {
    const newData = [...users];
    newData[index].activeButton = type;
    setUsers(newData);

    const intervalId = setInterval(() => {
      if (type === 'minus') {
        handleMinusClick(index);
      } else if (type === 'plus') {
        handlePlusClick(index);
      }
    }, 130);

    const handleButtonMouseUp = () => {
      clearInterval(intervalId);
      newData[index].activeButton = null;
      setUsers(newData);
      window.removeEventListener('mouseup', handleButtonMouseUp);
    };

    window.addEventListener('mouseup', handleButtonMouseUp);

  };

  const filteredData = filterTableData(users, searchInput);


  const handleRedeem=(index)=>
    {

      if(redeemPoints <= filteredData[index].points && redeemPoints>0)
        {
          UpdateRedeemPoints(filteredData[index].gmail,(filteredData[index].points-redeemPoints),setReload,setDashmsg,setRedeemPoints,fetchAllData);
        }
      else if(redeemPoints > filteredData[index].points)
        {
          setDashmsg("Exceed Total Points")
        }
        else{
          setDashmsg("Invalid Points Value..!")
        }
    }

  return (
    <div className="search-container">
        <ToastContainer theme="dark" />
      <img className="usernavlogo" src="../logoblack.png" alt="logo" />

      <div className="table-container">
        <div className='wrapper'>
          <div className="search-wrapper">
            <input
              type="text"
              placeholder="Search Here"
              className="search-input"
              value={searchInput}
              onChange={handleSearchInputChange}
            />
            <button type="submit" className="search-button">
              <FontAwesomeIcon icon={faSearch} className="icon" />
            </button>
          </div>
          <div className='total_user_circle'>
            <div className='total_user_content'>
              <div className='total_user'>Total Users</div>
              <div className='total_user_count'>{users.length}</div>
            </div>
          </div>
        </div>
        <table className="styled-table">
          <thead>
            <tr>
              <th>Username</th>
              <th>Email</th>
              <th>Total Points</th>
              <th>Redeem Points</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((row, index) => (
              <tr key={index}>
                <td>{row.username}</td>
                <td>{row.gmail}</td>
                <td>{row.points}</td>
                <td>
                  <input type="number" className='points_inputs_box' style={{height:"30px", padding:"5px"}} onChange={(e)=>setRedeemPoints(e.target.value)} />
                </td>
                <td>
                  <button className='redeembutton' onClick={()=>handleRedeem(index)} >Redeem</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default OwnerDashboard;
