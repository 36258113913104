import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './mobileLogin.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { loginreq } from '../../api/authRequests';


const MobileLogin = ({ handleKeyPress,handleSubmit, setEmail,setPassword,showPassword,setShowPassword, handleForgetPass,showspinner }) => {

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  
  
  return (
    <div className='mob_signup_container'>
      <ToastContainer theme="dark" />
      <div className='mob_signup_div'>
        <div className='mob_signup_title_cont'>
        <div className="mob_signup_title">Welcome </div>
          <div className="mob_signup_subtitle">Log in to your account</div>
        </div>
        <form className="login_form" onKeyPress={handleKeyPress} >
          <input type="email"  className="signup_username_inp_box"
           placeholder='Email'
           name="email"
           onChange={e => setEmail(e.target.value)}
           />
          <div style={{ position: 'relative' }}>
            <input
              type={showPassword ? 'text' : 'password'}
              className="signup_username_inp_box"
              name="password"
              placeholder='Password'
              onChange={e => setPassword(e.target.value)}
              style={{ paddingRight: '30px' }}
            />
            <button
              onClick={togglePasswordVisibility}
              id="mobile_eye"
              type="button"
              aria-label={showPassword ? 'Hide password' : 'Show password'}
            >
              <FontAwesomeIcon icon={showPassword ? faEyeSlash  :faEye  } />
            </button>
          </div>
          <button  className="mob_signup_haveacc-forgetpass" onClick={(e)=>handleForgetPass(e)} style={{backgroundColor:"transparent",border:"none",cursor:"pointer"}}>
                      Forgot Password?
            </button>
          <button className="signup_username_inp_box mob_createacc_now" type='submit' onClick={(e)=>handleSubmit(e)}>Login</button>
          
        </form>
      
        <div className='mob_signup_haveacc'>Don’t  Have An Account? <Link to="/signup" className='mob_signup_haveacc'>Sign Up</Link></div>
        <div className="loading-mobile" style={showspinner? {visibility:"visible"} : {visibility : "hidden"}}></div>
      </div>
    </div>
  );
};

export default MobileLogin;
