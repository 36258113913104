import React from "react";
import "./branding.css";

const Branding = () => {
  return (
    <div className="branding-container">
      <div className="branding-title">
        Reaching the brands is our top priority
      </div>
      <div className="branding-flex">
        <div className="branding-flex-left">
          <div className="branding-flex-words">
            At A3, we pride ourselves on curating a diverse selection of
            clothing from various brands under one roof.
          </div>
          <div className="branding-flex-words">
            Our dedication to providing a wide range of options allows every
            individual to effortlessly reach the brands they desire, making A3
            the ultimate destination for fashion enthusiasts seeking quality and
            variety.
          </div>
        </div>
        <div className="branding-flex-right">
          <div className="branding-flex-words">
            Our meticulous process involves scouting for the latest trends and
            sought-after styles from renowned brands across the fashion
            spectrum.
          </div>
          <div className="branding-flex-words">
            Whether it's high-end luxury labels or emerging designers, we
            handpick each garment with precision and care, ensuring that our
            customers have access to an unparalleled collection.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Branding;
