import React, { useState, useEffect } from "react";
import "./LandingPage.css";
import Navbar from "../../components/navbar/Navbar";
import { Link } from "react-router-dom";
import Earnpoints from "../earnpoints/Earnpoints";
import Branding from "../branding/Branding";
import BrandsYouLove from "../brandsYouLove/BrandsYouLove";
import MakeUsDiff from "../makeusdifferent/MakeUsDiff";
import Radiant from "../radiantThreads/Radiant";
import ReachUs from "../reachUs/ReachUs";
import Login from "../login/Login";
import { ToastContainer, toast } from 'react-toastify';
const Landingpage = ({logoutmsg,setlogoutmsg}) => {

  useEffect(()=>
  {
    if(logoutmsg!=="")
      {
        toast(logoutmsg);
        setlogoutmsg("");
      }
  })

  return (
    <div className="container">
      <Navbar />
      <ToastContainer theme="dark" />
      <div className="main-flex">
        <div className="main-text">
          <div>Everything You Need</div>
         
          <div>To Do is <span className="main-text-span">Binge-Watch</span></div>
          
          <div>The Videos While</div>
          
          <div>Enjoying your coffee.</div>
        </div>
        <div className="sub-text">
          Grabbing the offer for your dresses is now easy, You can earn the
          
          redeem points by watching the videos and Grab the cool offers
          
          when you visit the store.
        </div>
        <Link to="/signup" className="takeMe-btn">
          Take Me There
        </Link>
      </div>

      <MakeUsDiff />
      <Earnpoints />
      <Radiant />
      <Branding />
      <BrandsYouLove />
      <ReachUs />

      {/* <Login /> */}
    </div>
  );
};

export default Landingpage;
