// Signup.js
import React, { useState, useEffect } from "react";
import "./forgetpass.css";
import { Link, useNavigate } from "react-router-dom";
import MobileForgetpass from "../Forgetpass/MobileForgetpass";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import validator from 'validator';
import { resetPassword } from "../../api/authRequests";

const ForgetPass = ({resetotp,useremail,setLoginmsg,errorMessagetoast,setErrorMessagetoast}) => {
  const [isMobileView, setIsMobileView] = useState(false);
  const [errorMessage, setErrorMessage] = useState("") 
  const [otpCorrect,setOtpCorrect]=useState(false);
  const [enteredOtp,setEnteredOtp]=useState("");
  const [repassword, setrepassword] = useState("");
  const [password, setPassword] = useState("");
  const [redirectlogin, setredirectlogin] = useState(false);

  let navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 730);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const validate = (value) => {
    setPassword(value);
      if (validator.isStrongPassword(value, {
          minLength: 8, minLowercase: 1,
          minUppercase: 1, minNumbers: 1, minSymbols: 1
      })) {
          setErrorMessage('Strong Password');
          setPassword(value);
      } else {
          setErrorMessage('Weak Password');
      }
    };

    const handleFormClick=(e)=>
    {
       e.preventDefault();
        if(password === "" && repassword === ""){
          setErrorMessagetoast("Please Fill all the fields..!");
        }
        else if(password !== repassword)
        {
          setErrorMessagetoast("Passwaord and Re-enter dosen't match..!");
        }
       else if(repassword === password && setOtpCorrect)
        {
           resetPassword(useremail,password,setredirectlogin,setErrorMessagetoast,setLoginmsg);
        }
        
    }

    const handleOtpEntered=()=>
    {
       if(enteredOtp === resetotp && enteredOtp.length === 4)
        {
          setOtpCorrect(true);
        }
        else if(enteredOtp.length > 4)
          {
            setErrorMessagetoast("OTP contains only 4 digits.") 
          }
        else if(enteredOtp.length === 4 )
        {
          setErrorMessagetoast("Wrong OTP")
        }
        else{
          setOtpCorrect(false)
        }
    }

    useEffect(()=>
    {
      handleOtpEntered();

    },[enteredOtp])

    useEffect(()=>
    {
      if(redirectlogin)
        {
          navigate('/Login'); 
        }
    },[redirectlogin])

  useEffect(()=>
    {
        if(errorMessagetoast !== "")
        {
            toast(errorMessagetoast);
            setErrorMessagetoast("");
        }

    },[errorMessagetoast,setErrorMessagetoast])
 
  return (
    <div>
      {isMobileView ? (
        <MobileForgetpass setEnteredOtp={setEnteredOtp} setErrorMessagetoast={setErrorMessagetoast}
        errorMessagetoast={errorMessagetoast} setPassword={setPassword}
        password={password} repassword={repassword} setrepassword={setrepassword}
        otpCorrect={otpCorrect} setOtpCorrect={setOtpCorrect}
        errorMessage={errorMessage} setErrorMessage={setErrorMessage}
        validate={validate} handleFormClick={handleFormClick} />
      ) : (
        <div className="login_container">
              <ToastContainer theme="dark" />
          <div className="center_login_container">
            <div className="login_left_section">
              <div className="login_welcome">Reset Password</div>
              <div className="login_input_section">
                <form className="login_form">
                  <label className="username_label">OTP</label>
                  <input type="text" placeholder="Please Enter OTP recieved to you mail id" onChange={(e)=>setEnteredOtp(e.target.value)} required className="username_inp_box" />
                  {otpCorrect?
                  (
                    <>
                      <label className="username_label">New Password</label>
                      <input
                        type="password"
                        required
                        className="username_inp_box"
                        onChange={(e) => validate(e.target.value)}
                      />

                      {errorMessage === '' ? null : 
                                     <span style={{ 
                                        fontWeight: 'bold',
                                        fontSize:'13px',
                                        marginBottom:'10px', 
                                        color: errorMessage === 'Strong Password' ? 'green' : 'red',
                                    }}>{errorMessage}</span>}

                      <label className="username_label">
                        Re-Enter New Password
                      </label>
                      <input
                        type="password"
                        required
                        className="username_inp_box"
                        onChange={(e)=>setrepassword(e.target.value)}
                      />
                  </>
                  
                  ):(<h5 style={{textAlign:"center" , marginBottom:"10px"}}>Please Enter OTP to Reset Password</h5>)}
                  
                  <button className="username_inp_box register_now" type="submit" onClick={(e)=>handleFormClick(e)}>
                    Reset Now
                  </button>
                </form>
              </div>
            </div>
            <div>
              <img className="sideframeimg" src="../SideFrame.jpg" alt="" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ForgetPass;
