import React, { useState, useEffect } from "react";
import "./signup.css";
import MobileSignup from "../mobile_signup/MobileSignup.jsx";
import { signup } from "../../api/authRequests.js";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate } from "react-router-dom";
import validator from 'validator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const Signup = ({ setLoginmsg }) => {
    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 730);
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [redirectlogin, setRedirectlogin] = useState(false);
    const [signupmsg, setSignupmsg] = useState("");
    const [showspinner, setShowSpinner] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [emailError, setEmailError] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    
    const validate = (value) => {
        if (validator.isStrongPassword(value, {
            minLength: 8, minLowercase: 1,
            minUppercase: 1, minNumbers: 1, minSymbols: 1
        })) {
            setErrorMessage('Strong Password');
            setPassword(value);
        } else {
            setErrorMessage('Weak Password');
        }
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (emailRegex.test(email)) {
            setEmailError('Valid Email');
            setEmail(email);
        } else {
            setEmailError('Please enter a valid email address');
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    let navigate = useNavigate();

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 730);
        };
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        setShowSpinner(true);
        if (username && email && password !== "") {
            signup(username, email, password, setRedirectlogin, setLoginmsg, setSignupmsg, setShowSpinner);
        } else {
            setSignupmsg("Please Fill all the fields..!");
            setShowSpinner(false);
        }
    };

    useEffect(() => {
        if (signupmsg !== "") {
            toast(signupmsg);
            setSignupmsg("");
        }
    }, [signupmsg]);

    useEffect(() => {
        if (redirectlogin) {
            navigate('/Login');
        }
    }, [redirectlogin, navigate]);

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSubmit(event);
        }
    };

    return (
        <div>
            {isMobileView ? (
                <MobileSignup 
                setLoginmsg={setLoginmsg} />
            ) : (
                <div className="login_container">
                    <ToastContainer theme="dark" />
                    <div className="center_login_container">
                        <div className="login_left_section">
                            <div className="login_welcome">
                                Welcome! 
                                <div className="loading" style={showspinner ? { visibility: "visible" } : { visibility: "hidden" }}></div>
                            </div>
                            <div className="login_input_section">
                                <form className="login_form" onSubmit={handleSubmit} onKeyPress={handleKeyPress}>
                                    <label htmlFor="username" className="username_label">User Name</label>
                                    <input
                                        type="text"
                                        className="username_inp_box"
                                        name="username"
                                        id="username"
                                        value={username}
                                        onChange={e => setUsername(e.target.value)}
                                    />
                                    <label htmlFor="email" className="username_label">Email</label>
                                    <input
                                        type="email"
                                        className="username_inp_box"
                                        name="email"
                                        id="email"
                                        onChange={e => validateEmail(e.target.value)}
                                    />
                                    {emailError && <span style={{ color: emailError === 'Valid Email' ? 'green' : 'red', fontSize: '15px', fontWeight: 'bold' }}>{emailError}</span>}
                                    <label htmlFor="password" className="username_label">Password</label>
                                    <div style={{ position: 'relative' }}>
                                        <input
                                            type={showPassword ? 'text' : 'password'}
                                            className="username_inp_box"
                                            name="password"
                                            id="password"
                                           
                                            onChange={(e) => validate(e.target.value)}
                                            style={{ paddingRight: '30px' }}
                                        />
                                        <button
                                            onClick={togglePasswordVisibility}
                                            id="eye_button"
                                            
                                            type="button"
                                            aria-label={showPassword ? 'Hide password' : 'Show password'}
                                        >
                                            <FontAwesomeIcon icon={showPassword ? faEyeSlash  :faEye  } />
                                        </button>
                                    </div>
                                    {errorMessage === '' ? null : 
                                    <span style={{ 
                                        fontWeight: 'bold', 
                                        color: errorMessage === 'Strong Password' ? 'green' : 'red',
                                    }}>{errorMessage}</span>}
                                    <button type="submit" className="username_inp_box register_now">
                                        Register Now
                                    </button>
                                </form>
                                {/* <p className="or_signup_with">Or Sign up With </p> */}
                            </div>
                            {/* <button className="google_btn">
                                <img src="googleIcon.png" alt="Google sign-up" className="google_icon" />
                                Google
                            </button> */}
                            <div className='mob_signup_haveacc-desk'>Have An Account? <Link to="/login" className='mob_signup_haveacc-desk'>Login</Link></div>
                        </div>
                        <div>
                            <img className="sideframeimg" src="../SideFrame.jpg" alt="Side Frame" />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Signup;
