import React from "react";
import "./earnpoints.css";

const Earnpoints = () => {
  return (
    <div className="earnptscontainer">
      <div className="subdiv">
        <div className="maintext">How To earn the points?</div>
        <div className="earnflex">
          <div className="earnpagecontainer">
            <img src="earnpageimage.jpg" className="earnpageimage" />
          </div>
          <div className="earnsteps">
            <div className="steptitle">Step 1:</div>
            <div className="step-para">
              Login or sign up your Account, You know what
              <br />
              it is completely Free
            </div>
            <div className="steptitle">Step 2:</div>
            <div className="step-para">
              There are plenty of Videos, Gently tap and
              <br />
              watch them and earn your points.
            </div>
            <div className="steptitle">Step 3:</div>
            <div className="step-para">
              Redeem the points on your next purchase in
              <br />
              the store.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Earnpoints;
