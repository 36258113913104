import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

const Redirect = () => {
    let navigator = useNavigate()

    useEffect(()=>
    {
        navigator("/");
    },[])

  return (
    <div>
      
    </div>
  )
}

export default Redirect
