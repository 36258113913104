import React from "react";
import "./reachUs.css";

const ReachUs = () => {
  return (
    <div className="reachUs-container">
      <div>
      <div className="adTitlespl">A3 Clothing</div>
      <div className="adTitle">Sakthi Centre,<br/> 8/2-4, Maruthamalai Rd,<br/> Vadavalli, Coimbatore,<br/>Tamil Nadu - 641041</div>
      <div className="adTitle">+91-90806 24423</div>
      <div className="adTitle">Clothingatathree@gmail.com</div>
      </div>
      <div className="reachus">Reach<br/><span className="reachus_span">Us !!</span></div>
    </div>
  );
};

export default ReachUs;
