import React from "react";
import "./navbar.css";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <div>
      <nav className="navbar">
        <img className="logo" src="../a3desktoplogo.png" alt="logo" />
        <div className="links">
          <Link to="/Login" className="login-btn btn">
            Login
          </Link>
          <Link to="/Signup" className="signup-btn">
            Sign Up
          </Link>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
