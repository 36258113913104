import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Landingpage from "./pages/landingPage/Landingpage";
import Login from "./pages/login/Login.jsx";
import Signup from "./pages/signup/Signup.jsx";
import MakeUsDiff from "./pages/makeusdifferent/MakeUsDiff.jsx";
import Earnpoints from "./pages/earnpoints/Earnpoints.jsx";
import UserDashboard from "./pages/userDashboard/UserDashboard.jsx";
import OwnerDashboard from "./pages/ownerDashboard/OwnerDashboard.jsx";
import MobileSignup from "./pages/mobile_signup/MobileSignup.jsx";
import MobileLogin from "./pages/mobileLogin/MobileLogin.jsx";
import { useEffect, useState } from "react";
import ForgetPass from "./pages/Forgetpass/ForgetPass.jsx";
import Redirect from "./pages/Redirect.js";
import { checktoken } from "./api/authRequests.js";
import axios from "axios";


function App() {
  const [loggedin,setLoggedin] = useState(false);
  const [adminloggedin,setAdminLoggedin] = useState(false);
  const [loginmsg , setLoginmsg] = useState(""); 
  const [resetotp,setresetotp]=useState("");
  const [useremail,setuseremail]=useState("");
  const [errorMessagetoast, setErrorMessagetoast] = useState("") 
  const [logoutmsg,setlogoutmsg] = useState("");

  useEffect(()=>
    { 
      if(document.cookie.startsWith("admintoken"))
      {
        setAdminLoggedin(true);
      }
      else if(!loggedin)
        {
          if(document.cookie !== "")
            {
             setLoggedin(true);
            }else
            {
             setLoggedin(false);
            }
        }
    },[])
  return (
    
    <BrowserRouter>
    
      <div className="App">
        {/* <Login /> */}
        {/* <Signup/> */}
        {/* <MobileSignup /> */}
        {/* <MobileLogin/> */}
        {/* <Landingpage /> */}
        {/* <Earnpoints/> */}
        {/* <UserDashboard/> */}
        {/* <OwnerDashboard/> */}
   
     
          {(!loggedin && !adminloggedin)?
              (
                <Routes>
                    <Route path="/" element={<Landingpage logoutmsg={logoutmsg} setlogoutmsg={setlogoutmsg}/>} />
                      <Route path="/login" element={<Login setLoggedin={setLoggedin} loginmsg={loginmsg} setLoginmsg={setLoginmsg} setAdminLoggedin={setAdminLoggedin} resetotp={resetotp} setresetotp={setresetotp} setuseremail={setuseremail} setErrorMessagetoast={setErrorMessagetoast}/>} />
                      <Route path="/signup" element={ <Signup setLoginmsg={setLoginmsg}/>} />
                      <Route path="/forgetpass" element={ <ForgetPass resetotp={resetotp} useremail={useremail} setLoginmsg={setLoginmsg} errorMessagetoast={errorMessagetoast}  setErrorMessagetoast={ setErrorMessagetoast}/>} />
                      <Route path="*" element={<Redirect/>}/>
                    </Routes>

              ):(!adminloggedin)?
                     (
                        <Routes>

                          <Route path="/" element={<UserDashboard setLoggedin={setLoggedin} setAdminLoggedin={setAdminLoggedin} setlogoutmsg={setlogoutmsg}/> } />
                          <Route path="*" element={<Redirect/>}/>
                        </Routes>
                     ):(
                        <Routes>
                          <Route path="/" element={<OwnerDashboard/>} />
                          <Route path="*" element={<Redirect/>}/>
                        </Routes>
                      )
    
            }
         
          
      </div>
    </BrowserRouter>
  );
}

export default App;
