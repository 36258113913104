import React, { useEffect, useState } from "react";
import "./mobileForgetpass.css";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';

const MobileForgetpass = ({errorMessage,validate,handleFormClick,setErrorMessage,setEnteredOtp,errorMessagetoast,setErrorMessagetoast,setPassword,password,repassword,setrepassword,otpCorrect,setOtpCorrect}) => {

  const [mobileLoading,setMobileLoading]=useState(false);

  useEffect(()=>
  {
    if(errorMessagetoast !== "")
      {
        setMobileLoading(false);
      }

  },[])

  const handleSubmit =(e)=>
    {
      handleFormClick(e);
      //setMobileLoading(true);
    }

  return (
    <div className="mob_signup_container">
      <ToastContainer theme="dark" />
      <div className="mob_signup_div">
        <div className="mob_signup_title_cont">
          <div className="mob_signup_title">Hey There !</div>
          <div className="mob_signup_subtitle">Let’s Reset Your Password</div>
        </div>
        <form className="login_form">
          <input
            type="text"
            required
            className="signup_username_inp_box"
            placeholder="OTP"
            onChange={(e)=>setEnteredOtp(e.target.value)}
          />
          {otpCorrect?
          (  
          <>
            <label className="username_label">New Password</label>
            <input
              type="password"
              placeholder="New Password"
              className="signup_username_inp_box"
              onChange={(e) => validate(e.target.value)}
            />
            {errorMessage === '' ? null : 
                            <span style={{ 
                              fontWeight: 'bold',
                              fontSize:'13px',
                              marginBottom:'10px', 
                              color: errorMessage === 'Strong Password' ? 'green' : 'red',
                          }}>{errorMessage}</span>}

            <label className="username_label">
              Re-Enter New Password
            </label>
            <input
              type="password"
              placeholder="Re-Enter New Password"
              className="signup_username_inp_box"
              onChange={(e)=>setrepassword(e.target.value)}
            />

          <button className="signup_username_inp_box mob_createacc_now" type="submit" onClick={(e)=>handleSubmit(e)}>
            Reset Password
          </button>
          </>
                  
          ):(<h5 className="mob_signup_haveacc" style={{textAlign:"center" , marginBottom:"10px"}}>Please Enter OTP to Reset Password</h5>)}

        </form>
        <div className='mob_signup_haveacc'>Go back to <Link to="/login" className='mob_signup_haveacc'>Sign in?</Link></div>
        <div className="loading-mobile" style={mobileLoading? {visibility:"visible"} : {visibility : "hidden"}}></div>
      </div>
    </div>
  );
};

export default MobileForgetpass;
