// Login.jsx
import React, { useState, useEffect } from "react";
import "./login.css";
import MobileLogin from "../mobileLogin/MobileLogin.jsx";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { loginreq, sendPassResetEmail } from "../../api/authRequests.js";
import { useNavigate   } from "react-router-dom";
import validator from 'validator'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';


const Login = ({setLoggedin,loginmsg,setLoginmsg,setAdminLoggedin,resetotp,setresetotp,setErrorMessagetoast}) => {

  const [isMobileView, setIsMobileView] = useState(false);
  const [showspinner , setShowSpinner] = useState(false);
  const [email,setEmail]=useState("");
  const [password,setPassword]=useState("");
  const [errorMessage, setErrorMessage] = useState('') 
  const [emailError, setEmailError] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const [otpsentstate,setOtpsentstate]=useState(false);


  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 730);
    };
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [isMobileView]);
  
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
};

let navigate = useNavigate();

  useEffect(()=>
    {
        if(loginmsg !== "")
        {
            toast(loginmsg);
            setLoginmsg("");
        }

    },[loginmsg,setLoginmsg])

    const handleSubmit=(e)=>
    {
        e.preventDefault();
        setShowSpinner(true);
        if( email && password !=="")
         { 
           loginreq(email,password,setLoggedin,setLoginmsg,setShowSpinner,setAdminLoggedin);
         }
         else
         {
            setLoginmsg("Please Fill all the fields..!")
            setShowSpinner(false);
         }
    }
     
    const validate = (value) => { 
  
           setPassword(value)  
  } 

  const validateEmail = (email) => {
      
          setEmail(email)
      
  };


  const handleForgetPass=(e)=>
    {
      setShowSpinner(true);
       e.preventDefault();
       if(email === "")
        {
          setLoginmsg("Please Fill Email to reset password..!")
          setShowSpinner(false)
        }
        else
        {
          const otp = generateOtp()
        // console.log(otp)
          setresetotp(otp);
          //console.log(resetotp)
          sendPassResetEmail(email,otp,setOtpsentstate,setLoginmsg, setShowSpinner)
        }
    }
        
    useEffect(()=>
    {
       if(otpsentstate)
        {
          setErrorMessagetoast("OTP Sent Sucessfully..!");
          navigate('/forgetpass');  
        }
    },[otpsentstate])

    const generateOtp=()=>
      {
  
          const digits = '0123456789';
          let OTP = '';
          for (let i = 0; i < 4; i++) {
              OTP += digits[Math.floor(Math.random() * 10)];
          }
          return OTP.toString();
      }
      
      const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
          handleSubmit(event);
        }
    };

  return (
    <div>
      {isMobileView ? (
        <MobileLogin 
        handleKeyPress={handleKeyPress}
        setLoggedin={setLoggedin}
        loginmsg={loginmsg}
        setLoginmsg={setLoginmsg}
        setAdminLoggedin={setAdminLoggedin}
        handleForgetPass={handleForgetPass}
        showspinner={showspinner}
        setShowSpinner={setShowSpinner}
        email={email}
        setEmail={setEmail}
        password={password}
        setPassword={setPassword}
        showPassword={showPassword}
        setShowPassword={setShowPassword}
        handleSubmit={handleSubmit}
          setresetotp={setresetotp}
          
        />

      ) : (
        <div className="login_container">
            <ToastContainer theme="dark" />
          <div className="center_login_container">
            <div className="login_left_section">
              <div className="login_welcome">Login <div className="loading" style={showspinner? {visibility:"visible"} : {visibility : "hidden"}}></div></div>
              <div className="login_input_section">
                <form className="login_form" onKeyPress={(e)=>handleKeyPress(e)}  handleKeyPress>
                  <label className="username_label">Email</label>
                  <input
                        type="email"                                        
                        className="username_inp_box"
                        name="email"
                        id="email"
                        onChange={e => validateEmail(e.target.value)}
                    />
                    <label htmlFor="password" className="username_label">Password</label>
                    <div style={{ position: 'relative' }}>
                    <input
                        type={showPassword ? 'text' : 'password'}
                        className="username_inp_box"
                        name="password"
                        id="password"
                        
                        onChange={(e) => validate(e.target.value)}
                        style={{ paddingRight: '30px' }}
                    />
                        <button
                            onClick={togglePasswordVisibility}
                            id="eye_button"
                            style={{
                                
                            }}
                            type="button"
                            aria-label={showPassword ? 'Hide password' : 'Show password'}
                        >
                            <FontAwesomeIcon icon={showPassword ? faEyeSlash  :faEye } />
                        </button>
                    </div>
                        {/* {errorMessage === '' ? null : 
                        <span style={{ 
                            fontWeight: 'bold', 
                            color: errorMessage === 'Strong Password' ? 'green' : 'red',
                        }}>{errorMessage}</span>}  */}
                  <div className="donthaveaccdiv">
                    <Link to="/signup" className="dont_have_acc">
                      Don’t Have a Account?
                    </Link>
                    <button className="forgot_pass" onClick={(e)=>handleForgetPass(e)} style={{backgroundColor:"transparent",border:"none",cursor:"pointer"}}>
                      Forgot Password?
                    </button>
                  </div>
                  <button className="username_inp_box login_now" type="submit" onClick={(e)=>handleSubmit(e)}>
                    Login Now
                  </button>
                </form>
                {/* <p className="or_signin_with">Or Sign in With </p> */}
              </div>
              {/* <button className="login_google_btn">
                <span className="google_word">
                  <img src="googleIcon.png" className="google_icon" />
                  Google
                </span>
              </button> */}
            </div>
            <div>
              <img
                className="sideframeimg"
                src="../SideFrame.jpg"
                alt=""
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;
