import React from "react";
import "./makeusdiff.css";

const MakeUsDiff = () => {
  return (
    <div className="makeusdiff-container">
      <div className="makeusdiff-title">
        <span className="makeusdiff-title-span">WHAT MAKES US</span> DIFFERENT?
      </div>
      <div className="makeusdiff-subtitle-flex">
        <div className="makeusdiff-subtitle">
          our Top Focus is to have your favorite brands on the store
        </div>
        <div className="makeusdiff-subtitle">
          We know you appreciate offers and discounts, and we have something for
          you.
        </div>
        <div className="makeusdiff-subtitle">
          All You Need To Do is Take The Notes!!!
        </div>
      </div>
    </div>
  );
};

export default MakeUsDiff;
