import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { signup } from "../../api/authRequests.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import validator from 'validator';
import './mobileSignup.css';

const MobileSignup = ({ setLoginmsg }) => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [redirectLogin, setRedirectLogin] = useState(false);
  const [signupMsg, setSignupMsg] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const validateEmail = (emailInput) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(emailInput)) {
      setEmailError('Valid Email');
      setEmail(emailInput);
    } else {
      setEmailError('Please enter a valid email address');
    }
  };

  const validatePassword = (passwordInput) => {
    if (validator.isStrongPassword(passwordInput, {
      minLength: 8, minLowercase: 1, minUppercase: 1, minNumbers: 1, minSymbols: 1
    })) {
      setPasswordError('Strong Password');
      setPassword(passwordInput);
    } else {
      setPasswordError('Weak Password');
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(prevState => !prevState);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setShowSpinner(true);
    if (username && email && password) {
      signup(username, email, password, setRedirectLogin, setLoginmsg, setSignupMsg, setShowSpinner);
    } else {
      setSignupMsg("Please fill all the fields!");
      setShowSpinner(false);
    }
  };

  useEffect(() => {
    if (signupMsg) {
      toast(signupMsg);
      setSignupMsg("");
    }
  }, [signupMsg]);

  useEffect(() => {
    if (redirectLogin) {
      navigate('/login');
    }
  }, [redirectLogin, navigate]);

  return (
    <div className='mob_signup_container'>
      <ToastContainer theme="dark" />
      <div className='mob_signup_div'>
        <div className='mob_signup_title_cont'>
          <div className='mob_signup_title'>Hey There!</div>
          <div className="mob_signup_subtitle">Let’s create an account</div>
          <div className="loading" style={{ visibility: showSpinner ? "visible" : "hidden" }}></div>
        </div>

        <form className="login_form" onSubmit={handleSubmit}>
          <input
            type="text"
            name="username"
            id="username"
            onChange={e => setUsername(e.target.value)}
            required
            className="signup_username_inp_box"
            placeholder='User Name'
          />

          <input 
            type="email"
            name="email"
            required
            className="signup_username_inp_box"
            placeholder='Email'
            onChange={e => validateEmail(e.target.value)}
          />
          {emailError && <span style={{ color: emailError === 'Valid Email' ? 'green' : 'red' }}>{emailError}</span>}
          <div style={{ position: 'relative' }}>
          <input
            type={showPassword ? 'text' : 'password'}
            className="signup_username_inp_box"
            name="password"
            id="password"
            placeholder='Password'
            onChange={e => validatePassword(e.target.value)}
            
          />
          <button
            onClick={togglePasswordVisibility}
            id="signupmobile"
            type="button"
            aria-label={showPassword ? 'Hide password' : 'Show password'}
          >
            <FontAwesomeIcon icon={showPassword ?  faEyeSlash: faEye } />
          </button>
          </div>
          {passwordError && <span style={{ color: passwordError === 'Strong Password' ? 'green' : 'red' }}>{passwordError}</span>}
          <button className="signup_username_inp_box mob_createacc_now ">Create Account</button>
        </form>
        {/* <div className='mob_signup_line'></div> */}
    {/* <button className="signup_username_inp_box mob_createacc_now gbtn"><span className='g_icon'><img src="GoogleIconwhite.png" className="g_icon" /></span>Google Sign In</button> */}
    <div className='mob_signup_haveacc'>Have An Account? <Link to="/Login" className='mob_signup_haveacc'>Login</Link></div>
         </div>
    </div>
  );
};

export default MobileSignup;
