import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './userDashboard.css';
import UserDashNav from '../../components/userDashNavbar/UserDashNav';
import UserPoints from '../../components/userPoints/UserPoints';
import ReachUs from '../reachUs/ReachUs';
import { getPoints } from '../../api/authRequests';
import channellogo from '../../logos/channels_profile.jpg'

const UserDashboard = ({setLoggedin,setAdminLoggedin,setlogoutmsg}) => {
  const [points, setPoints] = useState(-1);
  const [videoDetails, setVideoDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [limitreached , setlimitreached] = useState(false);
  const [pointslimitreached , setpointslimitreached] = useState(false);
  const [callApi,setcallapi]=useState(true);

  
useEffect(() => {
  getPoints(setPoints);

  if (callApi) {
    fetchRelatedVideos();
    setcallapi(false);
  }
}, [callApi, setPoints]); 

useEffect(() => {
  return () => {
    setcallapi(true);
  };
}, [setcallapi]);


  useEffect(() => {
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    tag.onload = () => {
      createPlayers();
    };

    return () => {
      tag.remove();
    };
  }, [videoDetails]);


  const fetchRelatedVideos = async () => {
    setIsLoading(true);
    setError(null);
    try {
       const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/videos`, {
          withCredentials: true
        });
      const videos = response.data;

      if(videos === "Today's Limit Exceeded")
      {
        setlimitreached(true)
        setVideoDetails([]);
      }
      else if(videos === "Please Redeem Your Points to Continue..!")
        {
          setpointslimitreached(true)
          setVideoDetails([]);
        }
      else if (Array.isArray(videos) && videos.length > 0) {
        setVideoDetails(videos);
      } else {
        setError('No videos found.');
        setVideoDetails([]);
      }
    } catch (error) {
      console.error('Error fetching related videos', error);
      setError(error.response ? error.response.data.message : 'Failed to fetch videos.');
      setVideoDetails([]);
    } finally {
      setIsLoading(false);
    }
  };

 

  const handleVideoWatched = async (videoId) => {
    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/watched`, {
        videoId: videoId
      }).then(()=>
      {
        fetchRelatedVideos();
        getPoints(setPoints);
      })
    } catch (error) {
      console.error('Error marking video as watched', error);
    }
  };

  const createPlayers = () => {
    videoDetails.forEach(video => {
      new window.YT.Player(`player-${video.item_ids.videoId}`, {
        videoId: video.item_ids.videoId,
        playerVars: {
          controls: 0, 
        },
        events: {
          'onStateChange': event => {
            if (event.data === window.YT.PlayerState.ENDED) {
              handleVideoWatched(video.item_ids.videoId);
            }
          }
        }
      })
    });
  };

  return (
    <div>
      <UserDashNav setLoggedin={setLoggedin} setlogoutmsg={setlogoutmsg} setAdminLoggedin={setAdminLoggedin}/>
      <div className='userDashContainer'>
        <div className='u_title'>You Love Offers ?</div>
        <div className='u_subtitle'>then do it as homework !!</div>
        <div className='userptscontainer'>
          <UserPoints className='userpts' points={points} />
        </div>

        {isLoading ? (
          <p>Loading videos...</p>
        ) : error ? (
          < div id='error-div'>
            <p>{error}</p>
            <button onClick={()=>fetchRelatedVideos} disabled={isLoading}>
              Retry
            </button>
          </div>
        ) : (
          videoDetails.length > 0 ? 
          videoDetails.map(video => (
            <div key={video.item_ids.videoId} className='videos'>
              <div id={`player-${video.item_ids.videoId}`}  className='video-size'/>
              <div className='video_downdiv '>
                <img className='video_icon' src={channellogo} alt='Icon' />
                <div className='video_details'>
                  <div className='video_title'>{video.snippet.title}</div>
                  <div className='video_author'>{video.snippet.channelTitle}</div>
                </div>
              </div>
            </div>
          )) :
          (
           limitreached?
            (
              < div id='error-div'>You have reached your limit today</div>
            ):(
              pointslimitreached?
              (
                      < div id='error-div'>Redeem your points to continue..!</div>
              ):(<></>)
            )
          )
        )}
      </div>
      <ReachUs />
    </div>
  );
};

export default UserDashboard;
